import {useState} from 'react';
import {AppBar as AppBarBase, Drawer as DrawerBase, Hidden, IconButton, Toolbar, Typography} from '@material-ui/core';
import MenuIcon from '@material-ui/icons/Menu';
import styled from 'styled-components';
import {useLocation} from 'react-router-dom';
import Sidebar from './Sidebar';
import {Breakpoints} from '../../constants';
import {routes} from './meta';

const drawerWidth = 240;

const Drawer = styled(DrawerBase)`
  .MuiPaper-root {
    width: ${drawerWidth}px;
  }
`;

const Nav = styled.nav`
  @media screen and (min-width: ${Breakpoints.Small}px) {
    width: ${drawerWidth}px;
    flex-shrink: 0;
  }
`;

const AppBar = styled(AppBarBase)`
  @media screen and (min-width: ${Breakpoints.Small}px) {
    width: calc(100% - ${drawerWidth}px);
    padding-left: ${drawerWidth}px;
  }
`;

const MenuButton = styled(IconButton)`
  margin-right: 24px;
  
  && {
    @media screen and (min-width: ${Breakpoints.Small}px) {
      display: none;
    }
  }
`;

const Navigation = () => {
  const [isMobileOpen, setIsMobileOpen] = useState(false);
  const {pathname} = useLocation();

  // default to first if we don't find the route
  const { title } = routes.find(({path}) => path === pathname) ?? routes[0];

  const handleDrawerToggle = () => {
    setIsMobileOpen(!isMobileOpen);
  };

  return (
    <>
      <AppBar position="fixed">
        <Toolbar>
          <MenuButton
            color="inherit"
            edge="start"
            onClick={handleDrawerToggle}
          >
            <MenuIcon/>
          </MenuButton>
          <Typography variant="h6" noWrap>
            {title}
          </Typography>
        </Toolbar>
      </AppBar>
      <Nav>
        <Hidden smUp implementation="css">
          <Drawer
            variant="temporary"
            anchor="left"
            open={isMobileOpen}
            onClose={handleDrawerToggle}
            ModalProps={{
              keepMounted: true, // Better open performance on mobile.
            }}
          >
            <Sidebar/>
          </Drawer>
        </Hidden>
        <Hidden xsDown implementation="css">
          <Drawer
            variant="permanent"
            open
          >
            <Sidebar/>
          </Drawer>
        </Hidden>
      </Nav>
    </>
  );
}

export default Navigation;
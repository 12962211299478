import axios from 'axios';

const baseUrl = 'https://builds.universesandbox.com/dashboard';
const stringChanges = '/string-changes';
const tags = '/tags';

const unsecureDataUrl = 'http://data.universesandbox.com';
const dataUrl = 'https://data.universesandbox.com/universe-sandbox';
const testDb = '/automated-test-results';

export const apiKeys = {
  dbKey: '',
  uploadKey: '',
};

const httpGet = async (url) => {
  const result = await axios.get(url);
  return result.data;
};

const httpPost = async (url, data) => {
  const result = await axios.post(url, data);
  return result.data;
};

const httpGetAuth = async (url) => {
  const result = await axios.get(url, {headers: {'Authorization': apiKeys.dbKey}});
  return result.data;
};

export const isDev = window.location.hostname === "localhost";
export const getTags = async () => await httpGet(`${baseUrl}${tags}`);
export const getTextChanges = async (tag) => await httpGet(`${baseUrl}${stringChanges}?tag=${tag}`);
export const getTestData = async (keys, filter, sort) => 
{
  let params = new URLSearchParams({keys: JSON.stringify(keys), filter: JSON.stringify(filter), sort: JSON.stringify(sort)});

  let keysForDel = [];
  params.forEach((v, k) => {
    if (v == 'null'){
      keysForDel.push(k);
    }
  });
  keysForDel.forEach(k => {
    params.delete(k);
  });

  return await httpGetAuth(`${dataUrl}${testDb}?${params.toString()}`);
};

export const getTestDataAction = async (action, props) => await httpGetAuth(`${dataUrl}${testDb}/_aggrs/${action}?avars=${JSON.stringify(props)}`);

export const saveFile = async data => await httpPost(`${unsecureDataUrl}/api/upload`, data);
export const getApiTokens = async token => {
  const response = await httpGet(`${baseUrl}/get-api-keys?id_token=${token}`);
  apiKeys.dbKey = response.dbKey;
  apiKeys.uploadKey = response.uploadKey;
};

export const verifyCookie = (cookies) => {
  if (cookies.hasOwnProperty('apiKeys')){
    let response = cookies.apiKeys;
    apiKeys.dbKey = response.dbKey;
    apiKeys.uploadKey = response.uploadKey;
    return true;
  }
  return false;
}

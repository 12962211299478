import {useEffect, useState} from 'react';
import {getTextChanges} from '../../../lib/net';

const groupByFile = (diffsByFile, currentLine) => {
  const isFile = currentLine.startsWith('---') || currentLine.startsWith('+++');
  const isDiff = !isFile && (currentLine.startsWith('-') || currentLine.startsWith('+'));
  const lastFile = diffsByFile.slice(-1)[0];

  if (isFile) {
    const fileNameStartIndex = 6; // this is hacky and brittle... assumes line is "+++ a/<the actual path>"
    const fileName = currentLine.substr(fileNameStartIndex);

    if (lastFile?.fileName === fileName)
      return diffsByFile;

    return [
      ...diffsByFile,
      {
        fileName,
        diffs: [],
      }
    ];
  }

  else if (isDiff) {
    const firstFiles = diffsByFile.slice(0, -1);

    return [
      ...firstFiles,
      {
        ...lastFile,
        diffs: [...lastFile.diffs, currentLine],
      },
    ];
  }

  return diffsByFile;
};

let unknownLines = [];

const joinMultilineDiffs = (diffs, currentLine) => {
  const newEntryRegex = /"[A-Za-z0-9_\-.:]+"/;
  const isNewEntry = !!currentLine.match(newEntryRegex);

  if (isNewEntry)
    return [...diffs, [currentLine]];
  else {
    if (diffs.length === 0) {
      unknownLines.push(currentLine);
      return diffs;
    }

    const firstDiffs = diffs.slice(0, -1);
    const lastDiff = diffs.slice(-1)[0];

    if (!lastDiff) {
      unknownLines.push(currentLine);
      return diffs;
    }

    return [...firstDiffs, [...lastDiff, currentLine]];
  }
};

const groupMultilineDiffs = (diffsByFile, fileDiff) => {
  return [
    ...diffsByFile,
    {
      ...fileDiff,
      diffs: fileDiff.diffs.reduce(joinMultilineDiffs, []),
    },
  ];
}

const keyRegex = /"[A-Za-z0-9_\-.:]+"/;

const createDiffObjects = (diffsByFile, fileDiff) => [
  ...diffsByFile,
  {
    ...fileDiff,
    diffs: fileDiff.diffs.map(lines => {
      const firstDiff = lines[0];
      const key = firstDiff.match(keyRegex)[0].replaceAll('"', '');
      const guidRegex = /[A-Za-z0-9]{32}/;
      const guid = key.match(guidRegex)?.[0];

      return {
        key,
        guid,
        type: firstDiff.startsWith('+') ? 'add' : 'remove',
        lines,
      }
    })
  }
];

const cleanTextLines = (diffsByFile, fileDiff) => [
  ...diffsByFile,
  {
    ...fileDiff,
    diffs: fileDiff.diffs.map(diff => ({
      ...diff,
      lines: diff.lines.map((line, i) => {
        const isFirstLine = i === 0;
        const parsedLine =
          line
            .replace(/^[+-]/, '')
            .replace(',"', '')
            .replace('",""', '')
            .replace('""', '')
            .replace(/\r/, '');

        if (isFirstLine) {
          return parsedLine.replace(keyRegex, '');
        }

        return parsedLine;
      })
    }))
  }
];

const groupChangeDiffs = (diffsByFile, fileDiff) => {
  const fileDiffWithGroupedChanges = {
    ...fileDiff,
    diffs: fileDiff.diffs.reduce((groupedDiffs, diff) => {
      const otherDiff = fileDiff.diffs.find(otherDiff => otherDiff.key === diff.key && otherDiff.type !== diff.type);

      if (!otherDiff)
        return [ ...groupedDiffs, diff ];

      const diffHasAlreadyBeenAdded = groupedDiffs.some(processedDiff => processedDiff.key === diff.key);
      if (diffHasAlreadyBeenAdded)
        return [...groupedDiffs];

      const [addDiff, removeDiff] = diff.type === 'add' ? [diff, otherDiff] : [otherDiff, diff];

      return [
        ...groupedDiffs,
        {
          type: 'change',
          key: diff.key,
          addLines: addDiff.lines,
          removeLines: removeDiff.lines
        }
      ];
    }, []),
  }

  return [
    ...diffsByFile,
    fileDiffWithGroupedChanges,
  ];
};

// Git is reporting changes when lines are identical due to changes in whitespace/returns and such.  We don't care about
// those kinds of changes, so we'll filter them out.
const filterOutIdenticalChangeDiffs = (diffsByFile, fileDiff) => {
  const cleanedFileDiffs = fileDiff.diffs.reduce((uniqueDiffs, diff) => {
    if (diff.type !== 'change')
      return [...uniqueDiffs, diff];

    for (let i = 0; i < diff.addLines.length; i++) {
      const addDiffLine = diff.addLines[i];
      const removeDiffLine = diff.removeLines[i];

      if (addDiffLine !== removeDiffLine)
        return [...uniqueDiffs, diff];
    }

    return [...uniqueDiffs];
  }, []);

  if (cleanedFileDiffs.length > 0)
    return [...diffsByFile, { ...fileDiff, diffs: cleanedFileDiffs }];

  return [...diffsByFile];
};

const getDuplicateGuids = (parsedDiff) => {
  const processedGuids = [];
  const duplicateGuids = [];

  parsedDiff.forEach(file => {
    file.diffs.forEach(diff => {
      const guid = diff.guid;

      if (!guid)
        return;

      if (processedGuids.includes(guid) && !duplicateGuids.includes(guid)) {
        duplicateGuids.push(guid);
      }

      processedGuids.push(guid);
    });
  });

  return duplicateGuids;
}

const groupRelatedGuids = (parsedDiff) => {
  const duplicateGuids = getDuplicateGuids(parsedDiff);
  const diffsByGuid = {};

  duplicateGuids.forEach(guid => {
    parsedDiff.forEach(file => {
      const fileDiffs = [];

      file.diffs.forEach(diff => {
        if (diff.guid !== guid) {
          fileDiffs.push(diff);
          return;
        }

        diffsByGuid[guid] = {
          ...diffsByGuid[guid],
          fileName: guid,
          diffs: [
            ...diffsByGuid[guid]?.diffs || [],
            diff
          ]
        }
      })

      file.diffs = fileDiffs;
    })
  })

  parsedDiff.unshift(...Object.values(diffsByGuid));
}

const parseTextChanges = (rawDiff) => {
  const withLineDiff = line => line.startsWith('+') || line.startsWith('-');
  const withoutHeaderDiff = line => !line.includes('"Token","Translation","Other"');
  unknownLines = [];

  const parsedDiff = rawDiff
    .split('\n')
    .filter(withLineDiff)
    .filter(withoutHeaderDiff)
    .reduce(groupByFile, [])
    .reduce(groupMultilineDiffs, [])
    .reduce(createDiffObjects, [])
    .reduce(cleanTextLines, [])
    .reduce(groupChangeDiffs, [])
    .reduce(filterOutIdenticalChangeDiffs, []);

  groupRelatedGuids(parsedDiff);

  return parsedDiff;
}

const useTextChanges = (tag) => {
  const [textChanges, setTextChanges] = useState([]);
  const [loading, setLoading] = useState(false);
  const [fetched, setFetched] = useState(false);

  useEffect(() => {
    (async () => {
      if (!tag)
        return;

      setLoading(true);
      setFetched(false);
      const rawDiff = await getTextChanges(tag);
      const parsedTextChanges = parseTextChanges(rawDiff);
      setTextChanges(parsedTextChanges);
      setLoading(false);
      setFetched(true);
    })();
  }, [tag])

  return {
    textChanges,
    loading,
    fetched,
    unknownLines
  };
};

export default useTextChanges;

import PropTypes from 'prop-types';
import styled from 'styled-components';
import GoogleLoginBase from 'react-google-login';
import { Typography } from '@material-ui/core';
import { Button } from '@material-ui/core';
import { isDev } from '../../../lib/net'

const Wrapper = styled.div`
  padding: 20px;
  display: flex;
  height: 100vh;
  width: 100vw;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

const GoogleLogin = styled(GoogleLoginBase)`
  margin-top: 40px;
`;

const AuthenticationPage = ({ onAuth }) => (
  <Wrapper>
    <Typography variant='h6'>
      You must sign in with a universesandbox.com email address to continue.
    </Typography>
    <br />
    {!isDev &&
      <GoogleLogin
        clientId={process.env.REACT_APP_GOOGLE_CLIENT_ID}
        onSuccess={res => onAuth(res.getAuthResponse().id_token)}
      /> 
    }
    {isDev && 
      <Button 
      variant="contained"
      onClick={() => onAuth('3n8NY6LbLbGDff6iZ3Ejo')}
      >
      Login as Developer
      </Button>
    }

  </Wrapper>
);

AuthenticationPage.propTypes = {
  onAuth: PropTypes.func.isRequired,
};

export default AuthenticationPage;

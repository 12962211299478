import TextFields from '@material-ui/icons/TextFields';
import AssignmentIcon from '@material-ui/icons/Assignment';
import ExitToAppIcon from '@material-ui/icons/ExitToApp';
import { useCookies } from 'react-cookie';
import {useHistory} from 'react-router-dom';
import { TextChangesPage, AutomatedTestsPage } from '../Pages';

export const routes = [
  {
    title: 'Automated Test Results',
    path: '/test-results',
    Icon: () => <AssignmentIcon />,
    PageContents: () => <AutomatedTestsPage />
  },
  {
    title: 'Text Changes',
    path: '/text-changes',
    Icon: () => <TextFields />,
    PageContents: () => <TextChangesPage />
  },
  {
    title: 'Logout',
    path: '/logout',
    Icon: () => <ExitToAppIcon />,
    PageContents: () => {
      const [cookies, setCookie, removeCookie] = useCookies(['usx']);
      const history = useHistory();
      removeCookie('apiKeys');
      history.push("/");
      history.go("/");
    }
  }
];

import PropTypes from 'prop-types';
import styled from 'styled-components';

const Spacer = styled.div`
  height: 64px;
`;

const Main = styled.main`
  flex-grow: 1;
  padding: 24px;
`;

const Page = ({ children }) => (
  <Main>
    <Spacer/>
    {children}
  </Main>
);

Page.propTypes = {
  children: PropTypes.element.isRequired
};

export default Page;

import {Switch, Route} from 'react-router-dom';
import {Page} from '../Pages';
import {routes} from './meta';

const PageRouter = () => (
  <Page>
    <Switch>
      {routes.map(({path, PageContents}) => (
        <Route key={path} path={path}>
          <PageContents />
        </Route>
      ))}

      {/* Default to always showing first route */}
      <Route path="/">
        {routes[0].PageContents}
      </Route>
    </Switch>
  </Page>
);

export default PageRouter;

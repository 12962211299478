import { useEffect, useState } from 'react';
import { getTags } from '../../../lib/net';

const filterOldTags = (tag) => ![
  '24.1',
  'Update_24.1',
  '24.0.2',
  '22.2.2',
  'v0.19.7.2',
  'v0.19.7.1',
  'v0.19.6.1',
  'v0.19.6',
  'v0.19.5',
  '0.19.5',
].includes(tag);

const useGitTags = () => {
  const [tags, setTags] = useState([]);
  const [loading, setLoading] = useState(false);
  const [fetched, setFetched] = useState(false);

  useEffect(() => {
    (async () => {
      setLoading(true);
      const rawTags = await getTags();
      const parsedTags = rawTags.split('\n').filter(filterOldTags);
      setTags(parsedTags);
      setLoading(false);
      setFetched(true);
    })();
  }, [])

  return {
    tags,
    loading,
    fetched,
  };
};

export default useGitTags;

import {useState} from 'react';
import { v4 as uuid } from 'uuid';
import {
  Divider,
  FormControl as FormControlBase,
  FormHelperText,
  InputLabel,
  LinearProgress,
  MenuItem,
  Paper as PaperBase,
  Select,
  Typography,
} from '@material-ui/core';
import styled from 'styled-components';
import useGitTags from './useGitTags';
import useTextChanges from './useTextChanges';

const Paper = styled(PaperBase)`
  padding: 40px;
  margin-bottom: 20px;
`;

const AddCard = styled(Paper)`
  && {
    background-color: #C1E1C1;
  }
`;

const RemoveCard = styled(Paper)`
  && {
    background-color: #FAA0A0;
  }
`;

const ChangeCard = styled(Paper)`
  && {
    background-color: #FFF192;
  }
`;

const Wrapper = styled.div`
  height: calc(100vh - 64px - 24px - 24px);
`;

const CardHeader = styled(Typography)`
  word-break: break-word;
`;

const CardBody = styled(Typography)`
  word-break: break-all;
  
  && {
    font-size: 12px;
    color: rgba(0, 0, 0, .6)
  }
`;

const FormControl = styled(FormControlBase)`
  && {
    margin-bottom: 24px;
  }
`;

const FileNameHeader = styled(Typography)`
  word-break: break-word;
  color: gray;
  
  && {
    margin-top: 20px;
  }
`;

const TextChangesPage = () => {
  const [selectedTag, setSelectedTag] = useState('');
  const { tags, loading: tagsLoading } = useGitTags();
  const { textChanges, loading: textChangesLoading, fetched: textChangesFetched, unknownLines } = useTextChanges(selectedTag);
  const loading = tagsLoading || textChangesLoading;

  const formatLines = (line, i) => <span key={uuid()}>{i > 0 && <br />}{line}</span>;

  return (
    <Wrapper>
      <FormControl>
        <InputLabel id="demo-simple-select-helper-label">Tag</InputLabel>
        <Select
          labelId="demo-simple-select-helper-label"
          id="demo-simple-select-helper"
          value={selectedTag}
        >
          <MenuItem value="">
            <em>None</em>
          </MenuItem>
          {tags.map((tag) => (
            <MenuItem key={tag} value={tag} onClick={() => setSelectedTag(tag)}>{tag}</MenuItem>
          ))}
        </Select>
        <FormHelperText>Tag to compare string changes with</FormHelperText>
      </FormControl>

      {loading && (
        <LinearProgress />
      )}

      {textChangesFetched && textChanges?.length === 0 && (
        <Typography variant='h6'>
          No text changes
        </Typography>
      )}

      {unknownLines.length > 0 && (
        <>
          <Typography variant='h6'>
            The following diffs could not be associated to a GUID diff:
          </Typography>
          {unknownLines.map(line => (
            <Typography paragraph key={uuid()}>
              {line}
            </Typography>
          ))}
        </>
      )}

      {textChangesFetched && textChanges?.length > 0 && (
        <>
          <Typography variant='h6'>
            Total diffs: {textChanges.reduce((sumDiffs, fileDiff) => sumDiffs + fileDiff.diffs.length, 0)}
          </Typography>
          <Divider />
        </>
      )}

      {textChanges?.map(({ fileName, diffs }) => (
        <div key={fileName}>
          <FileNameHeader variant='h6'>
            {fileName}
          </FileNameHeader>

          {diffs.map(({ key, type, lines, addLines, removeLines }) => {
            if (type === 'change') {
              return (
                <ChangeCard key={key}>
                  <CardHeader variant='h6'>
                    Changed From:
                  </CardHeader>
                  <CardHeader paragraph>
                    {removeLines.map(formatLines)}
                  </CardHeader>
                  <CardHeader variant='h6'>
                    To:
                  </CardHeader>
                  <CardHeader paragraph>
                    {addLines.map(formatLines)}
                  </CardHeader>
                  <Divider />
                  <CardBody paragraph>
                    {key}
                  </CardBody>
                </ChangeCard>
              )
            }

            const Card = type === 'add' ? AddCard : RemoveCard;

            return (
              <Card key={key}>
                <CardHeader paragraph>
                  {lines.map(formatLines)}
                </CardHeader>
                <Divider />
                <CardBody paragraph>
                  {key}
                </CardBody>
              </Card>
            );
          })}
        </div>
      ))}
    </Wrapper>
  );
};

export default TextChangesPage;

import {useState} from 'react';
import {CssBaseline} from '@material-ui/core';
import styled from 'styled-components';
import { MuiThemeProvider, createMuiTheme } from '@material-ui/core/styles'; 
import Navigation, {PageRouter} from './Navigation';
import {BrowserRouter} from 'react-router-dom';
import {getApiTokens, apiKeys, verifyCookie} from '../lib/net';
import {AuthenticationPage} from './Pages';
import { useCookies } from 'react-cookie';

const Root = styled.div`
  display: flex;
`;

const App = () => {
  const [cookies, setCookie] = useCookies(['usx']);
  const [isAuthenticated, setIsAuthenticated] = useState(false);

  const darkTheme = createMuiTheme({
    palette: {
      type: 'dark',
      primary: {
        // light: will be calculated from palette.primary.main,
        main: '#694D99',
        // dark: will be calculated from palette.primary.main,
        // contrastText: will be calculated to contrast with palette.primary.main
      },
      secondary: {
        light: '#0066ff',
        main: '#0044ff',
        // dark: will be calculated from palette.secondary.main,
        contrastText: '#ffcc00',
      },
      // Used by `getContrastText()` to maximize the contrast between
      // the background and the text.
      contrastThreshold: 3,
      // Used by the functions below to shift a color's luminance by approximately
      // two indexes within its tonal palette.
      // E.g., shift from Red 500 to Red 300 or Red 700.
      tonalOffset: 0.2,
    },
  });

  const handleAuthentication = async (token) => {
    try {
      await getApiTokens(token);
      let expires = new Date()
      expires.setTime(expires.getTime() + (720 * 60 * 60 * 1000))
      setCookie('apiKeys', JSON.stringify(apiKeys), { path: '/',  expires})
      setIsAuthenticated(true);
    } catch (e) {
      console.log(e);
    }
  };

  if (verifyCookie(cookies) && !isAuthenticated){
    setIsAuthenticated(true);
  }


  return (
    <Root>
      <MuiThemeProvider theme={darkTheme}>
      <CssBaseline/>

      {!isAuthenticated && (
        <AuthenticationPage onAuth={handleAuthentication} />
      )}

      {isAuthenticated && (
        <BrowserRouter>
          <Navigation/>
          <PageRouter/>
        </BrowserRouter>
      )}
      </MuiThemeProvider>
    </Root>
  );
};

export default App;
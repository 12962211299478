import {Divider, List, ListItem, ListItemIcon, ListItemText} from '@material-ui/core';
import styled from 'styled-components';
import {useHistory, useLocation} from 'react-router-dom';
import Logo from '../../assets/logo-dark.png';
import {routes} from './meta';

const LogoImg = styled.img`
  height: 42px;
  margin:8px 4px 8px 30px;
`;

const Sidebar = () => {
  const history = useHistory();
  const location = useLocation();

  return (
    <div>
      <LogoImg alt="logo" src={Logo} />
      <Divider />
      <List>
        {routes.map(({ title, path, Icon }) => (
          <ListItem button key={path} onClick={() => history.push(path)} selected={location.pathname === path}>
            <ListItemIcon>
              <Icon />
            </ListItemIcon>
            <ListItemText primary={title} />
          </ListItem>
        ))}
      </List>
    </div>
  );
}

export default Sidebar;

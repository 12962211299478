import React, { useState, useEffect } from 'react';
//import './TestResultCard.css';
import {
  Grid,
  Typography,
  IconButton,
  TableRow,
  TableCell,
  Badge
} from '@material-ui/core';
import { withStyles, makeStyles } from '@material-ui/core/styles';
import { usePapaParse } from 'react-papaparse';

const StyledTableRow = withStyles((theme) => ({
  root: {
    '&:nth-of-type(odd)': {
      backgroundColor: theme.palette.action.hover,
    },
  },
}))(TableRow);

const TestPerformanceItem = (props) => {

  const average = array => array.reduce((a, b) => a + b) / array.length;
  const { readRemoteFile } = usePapaParse();
  const [firstFPSData, setFirstFPSData] = useState('');
  const [secondFPSData, setSecondFPSData] = useState('');
  const [difference, setDifference] = useState('');

   useEffect(() => {
    readRemoteFile(props.first.Entries[0].URL, {
        complete: (results) => {
          var data = [];
          for (var i = 5; i < results.data.length-3; i++){
              data.push(Number(results.data[i][1]));
          }
          setFirstFPSData(Math.round(average(data)));
        },
      });
  }, []);

  useEffect(() => {
    readRemoteFile(props.second.Entries[0].URL, {
      complete: (results) => {
        var data = [];
        for (var i = 5; i < results.data.length-3; i++){
            data.push(Number(results.data[i][1]));
        }
        setSecondFPSData(Math.round(average(data)));
      },
    });
  }, []);

  useEffect(() => {
    if (!firstFPSData || !secondFPSData) return;
    let diff = Math.round((Math.abs(firstFPSData - secondFPSData) / ((firstFPSData + secondFPSData) / 2)) * 100);
    let positive = secondFPSData > firstFPSData;
    let difference = {number: positive ? "+" + diff + "%" : "-" + diff + "%", color: 'grey'};
    if (diff > 5){
      difference.color = positive ? 'green' : 'red';

      if (!positive){ props.showWarning(true);}
    }
    setDifference(difference);
  }, [firstFPSData, secondFPSData]);


  return(
    <StyledTableRow key={props.first.Name}>
        <TableCell component="th" scope="row">
          {props.first.Name}
        </TableCell>
        <TableCell align="center">{firstFPSData ? firstFPSData : 0}</TableCell>
        <TableCell align="center">{secondFPSData ? secondFPSData : 0}</TableCell>
        <TableCell style={{"font-weight":"bolder", color: difference.color ? difference.color : 'white'}} align="right">{difference.number ? difference.number : "--"}</TableCell>
      </StyledTableRow>
  )
}

export default TestPerformanceItem;
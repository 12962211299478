import {useState, useEffect} from 'react';
import {
  Grid,
  FormControl as FormControlBase,
  Card,
  CardActions,
  CardContent,
  Button,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Select,
  Typography
} from '@material-ui/core';
import { withStyles, makeStyles } from '@material-ui/core/styles';
import WarningRoundedIcon from '@material-ui/icons/WarningRounded';
import MuiAlert from '@material-ui/lab/Alert';
import styled from 'styled-components';
import moment from 'moment';
import {getTestData, getTestDataAction} from '../../lib/net';
import TestPerformanceItem from '../Components/TestPerformanceItem/TestPerformanceItem';


function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

const Wrapper = styled.div`
  height: calc(100vh - 64px - 24px - 24px);
`;

const useStyles = makeStyles({
  root: {
    minWidth: 275,
  },
  bullet: {
    display: 'inline-block',
    margin: '0 2px',
    transform: 'scale(0.8)',
  },
  title: {
    fontSize: 22,
  },
});

const StyledTableCell = withStyles((theme) => ({
  head: {
    backgroundColor: theme.palette.common.black,
    color: theme.palette.common.white,
  },
  body: {
    fontSize: 14,
  },
}))(TableCell);

const StyledTableRow = withStyles((theme) => ({
  root: {
    '&:nth-of-type(odd)': {
      backgroundColor: theme.palette.action.hover,
    },
  },
}))(TableRow);


const AutomatedTestsPage = () => {
  const classes = useStyles();
  const [releaseDate, setReleaseDate] = useState('');
  const [developDate, setDevelopDate] = useState('');
  const [performanceResults, setPerformanceResults] = useState([]);
  const [showWarning, setShowWarning] = useState(false);

  const buildFilter = (system, commit) => {
    console.log("FILTER: " + system);
    let filter = {};
    filter['$and'] = [];
    filter['$and'].push({SystemIdentifier: system});
    filter['$and'].push({"BuildInfoJSON.VERSION_BRANCH": commit.BuildInfo.VERSION_BRANCH});
    filter['$and'].push({"BuildInfoJSON.VERSION_COMMIT_HASH_SHORT": commit.BuildInfo.VERSION_COMMIT_HASH_SHORT});
  
    return filter;
  }

  useEffect(() => {
    (async () => {
        let availableBranches = await getTestDataAction('commits-by-system', {systemId: "TEST-SERVER"});
        let release = availableBranches.find(i => i._id == "Release").commits.sort((a,b) => b.date.$date - a.date.$date)[0];
        let develop = availableBranches.find(i => i._id == "develop").commits.sort((a,b) => b.date.$date - a.date.$date)[0];
        let firstResultSet = await getTestData(null, buildFilter("TEST-SERVER", release), null);
        let secondResultSet = await getTestData(null, buildFilter("TEST-SERVER", develop), null);
        setReleaseDate(moment(firstResultSet[0].BuildInfoJSON.VERSION_DATE.split(" ")[0]).format("(MM-DD-YYYY)"));
        let secondHash = secondResultSet[0].BuildInfoJSON.VERSION_COMMIT_HASH_SHORT;
        setDevelopDate(moment(secondResultSet[0].EndTimestamp).format("(MM-DD-YYYY)"))
        let firstPerformanceSet = firstResultSet[0].Tests.filter(i => i.Category == "Performance");
        let secondPerformanceSet = secondResultSet[0].Tests.filter(i => i.Category == "Performance");
        setPerformanceResults({first: firstPerformanceSet, second: secondPerformanceSet})
    })();
  }, [])



  return (
    <Wrapper>
      <Card className={classes.root}>
      <CardContent>
          <Typography style={{ display: 'flex', alignItems: 'center', flexWrap: 'wrap', }} className={classes.title} color="textSecondary" gutterBottom>
           {showWarning && (<WarningRoundedIcon style={{ fill: '#ffa500', "font-size":"30px", }} />)}&nbsp;FPS
        </Typography>
        <TableContainer component={Paper}>
          <Table size="small">
            <TableHead>
              <StyledTableRow>
                <StyledTableCell>Name</StyledTableCell>
                <StyledTableCell align="center">Release<br /> {releaseDate ? releaseDate : ''}</StyledTableCell>
                <StyledTableCell align="center">Develop<br /> {developDate ? developDate : ''}</StyledTableCell>
                <StyledTableCell align="right"></StyledTableCell>
              </StyledTableRow>
            </TableHead>
            <TableBody>
            {performanceResults.first && performanceResults.first.map((result, index) => (
              <TestPerformanceItem showWarning={setShowWarning} first={performanceResults.first[index]} second={performanceResults.second[index]}></TestPerformanceItem>
            ))}
            </TableBody>
          </Table>
        </TableContainer>
        </CardContent>
      <CardActions>
      
      </CardActions>
    </Card>
   
    </Wrapper>
  );
}

export default AutomatedTestsPage;
